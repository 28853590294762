import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Banner from "../components/banner"
import Seo from "../components/seo"


const ThankYou = () => { 

    return (
<Layout>

            <section className="thanks">

                <div className="container">

                    <h1> Thank You for contacting us. We will get back in touch with you soon!</h1>

                </div>
            </section>

</Layout>

    )

}

export default ThankYou